import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header" style={{ fontSize: '48px' }}>
        K8s Staging 2
      </header>
    </div>
  );
}

export default App;
